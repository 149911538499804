export default {
  company: "Company",
  companyActions: "Company Actions",
  editCompany: "Edit Company",
  viewCompany: "View Detail",
  addCompany: "Add Company",
  addCompanies: "Add Companies",
  companyName: "Company Name",
  companyHeName: "Hebrew Name",
  companyUrl: "Company URL",
  save: "Save",
  companiesList: "Company List",
  id: "ID",
  action: "Action",
  basicDetails: "Basic Details",
  otherDetails: "Other Details",
  displayText: "Display Text",
  printingAndPricing: "Printing and Pricing",
  conditionsAndRestrictions: "Conditions and restrictions",
  companyLogo: "Company Logo",
  message: {
    companySuccessfullyDeleted: "Company cancelled successfully",
    companySuccessfullyAdded: "Company added successfully",
    companySuccessfullyEdited: "Company edited successfully",
  },
  field: {
    grandTotalRequested: "Grand total request",
    calendarEmail: "Calendar Email",
    phones: "Phones",
    email: "E-mail",
    password: "Password",
    addressLine1: "Address Line 1",
    addressLine2: "Address Line 2",
    addressLine3: "Address Line 3",
    authorizedDealerNo:"Authorized dealer no",
    occasionalCustomerNo: "Occasional customer no",
    vatRegNo: "VAT Reg. No.",
    totalRounding: "Total rounding",
    printingaManagerName: "Printing a manager name",
    bestRegards: "Best regards",
    activeManager: "Active manager",
    vatPrecentage: "Vat percentage",
    textAttheBeginningOfaProposal: "Text at the beginning of a proposal",
    textAttheEndOfProposal: "Text at the end of a proposal",
    textAttheBeginningoforder: "Text at the beginning of an order",
    textAttheEndoforder: "Text at the end of an order",
    textForDueDate: "Text for due date",
    textAtTheBeginningOfTheDelivery: "Text at the beginning of the delivery",
    textAtTheEndOfADeliveryNote: "Text at the end of a delivery note",
    textAtTheBeginningOfAnInvoice: "Text at the beginning of an invoice",
    textAtTheEndOfAnInvoice: "Text at the end of an invoice",
    beginningReceiptText: "Text at the beginning of a receipt",
    endReceiptText: "Text at the end of a receipt",
    printingDueDate: "Printing due date",
    balancePrintingOnInvoice: "Balance printing on invoice",
    topLogo: "Top logo",
    bottomLogo: "Bottom logo",
    aTitleIsRequired: "A title is compulsory",
    dateFormatLabel:"Date format should dd-mm-yyyy",
    dateFormatHint:"Default date format is yyyy-mm-dd",
    bookkeepingNumberIsRequired: "Bookkeeping No. is compulsory",
    documentIncludingVat: "Document Including VAT",
    totalIncludingVAT: "Total Including VAT",
    warehouseInveManage: "Warehouse Inventory Management",
    warehouseCompulsory: "Warehouse Compulsory"
  }
}