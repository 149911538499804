export default {
  product: 'פריט',
  productActions: "אפשרויות פריט",
  editProduct: "ערוך פריט",
  addProduct: "הוסף פריט",
  save:"שמור",
  productList: "רשימת פריטים",
  id: "קוד",
  action: "פעילות",
  group: "קבוצה",
  basicDetails: "מידע בסיסי",
  otherDetails: "מידע נוסף",
  displayText: "הצג טקסט",
  financial: "מידע כספי",
  resetFilter: "אפס מסנן",
  filters: "מסננים",
  execute: "בצע",
  reset: "אתחול",
  fieldView: "תצוגת שדה",
  debitReport: "דוח פריט",
  selectFields: "בחר שדות",
  confirm: "אשר",
  cancel: "לְבַטֵל",
  selectAll: "בחר הכל",
  excel: "Excel",
  update: "עִדכּוּן",
  customerPrice: "מחיר לקוח",
  supplierPrice: "מחיר הספק",
  price: "מחיר",
  discount: "הנחה %",
  customer: "לקוח",
  addCustomerPrice: "להוסיף מחיר לקוח",
  addSupplierPrice: "הוסף מחיר ספק",
  priceListGroup: "קבוצת מחירון",
  Quotation: "הצעת מחיר",
  Order: "הזמנה", 
  Delivery_Note: "תעודת משלוח",
  Invoice: "חשבונית",
  Credit_Invoice: "חשבונית זיכוי",
  Proforma_Invoice: "חשבון פרופורמה",
  Receipt_Tax_Invoice: "חשבונית/קבלה",
  Return_Certificate: "תעודת החזרה",
  message: {
    productSuccessfullyDeleted: "פריט נמחק בהצלחה",
    productSuccessfullyAdded: "פריט נוסף בהצלחה",
    productSuccessfullyEdited: "פריט נערך בהצלחה",
    pictureSuccessfullyDeleted: "תמונת פריט נמחקה בהצלחה",
    pictureSuccessfullyAdded: "תמונת פריט נמחקה בהצלחה",
    pictureSuccessfullyEdited: "תמונת פריט נערכה בהצלחה",
    customerPriceSuccessfullyDeleted: "מחיר הלקוח בוטל בהצלחה",
    customerPriceSuccessfullyAdded: "מחיר לקוח נוסף בהצלחה",
    customerPriceSuccessfullyEdited: "מחיר הלקוח נערך בהצלחה",
    supplierPriceSuccessfullyDeleted: "מחיר הספק בוטל בהצלחה",
    supplierPriceSuccessfullyAdded: "מחיר הספק נוסף בהצלחה",
    supplierPriceSuccessfullyEdited: "מחיר הספק נערך בהצלחה",
    priceSuccessfullyAdded: "המחירון נוסף בהצלחה",
    priceSuccessfullyEdited: "המחירון נערך בהצלחה",
    priceSuccessfullyDeleted: "המחירון בוטל בהצלחה",
  },
  field: {
    itemCode: "קוד פריט",
    itemDescription: "תאור פריט",
    selectPicture: "בחר תמונה",
    group: "קבוצה פריט",
    foreignDescription: "תאור לועזי",
    barcode: "ברקוד",
    lastPurchaseDate: "תאריך רכישה אחרון",
    lastPurchasePrice: "סכום רכישה אחרון",
    lastPurchaseSupllier: "ספק רכישה אחרון",
    lastSaleDate: "תאריך מכירה אחרון",
    lastSalePrice: "מחיר מכירה אחרון",
    lastSaleCustomer: "לקוח מכירה אחרון",
    purchasePrice: "עלות רכישה",
    salePrice: "מחיר מכירה",
    minimumSalePrice: "מחיר מינימום",
    purchaseAccountingNo: "הנהלת חשבונות רכישה.",
    saleAccountingNo: "הנהלת חשבונות מכירה.",
    itemWithoutVAT: 'פריט ללא מעמ',
    createdAt: "נוצר ב",
    updatedAt: "מעודכן ב",
    stockItem: "פריט מלאי",
    vatAssetsItem: 'מע”מ תשומות ציוד',
    vatRecognizedPercentage: "אחוז מעימ מוכר",
    maximumVatRecogPercentage : "המקסימום הוא 100"
  }
}