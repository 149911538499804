export default {
  employeeRequired: "Employee name is required",
  employeeNoRequired: "Employee number is required",
  employeeNoInteger: "Employee No. should be a number",
  bookkipingNoInteger: "Bookkeeping No. should be a number",
  bookkipingNoValidMessage: "Bookkeeping No. must be less than 15 characters",
  phoneNoInteger: "Phone No. should be a number",
  phoneNoValidMessage: "Phone No. must be between 7 and 17",
  daysInteger: "Days should be a number",
  daysValidation: "Days must be between 0 to 31",
  monthsInteger: "Months should be a number",
  monthsValidation: "Months must be between 0 to 12",
  faxInteger: "Fax should be a number",
  faxValidMessage: "Fax must be between 7 and 17",
  spaceValidValue: "Please insert valid value",
  roleRequired: "Role is required",
  customerRequired: "Customer name is required",
  companyRequired: "Company name is required",
  companyValidMessage: "Company name must be at least 5 characters long",
  authorizedNoRequired: "Authorized number is required",
  contactRequired: "Contact name is required",
  descriptionRequired: "Description is required",
  tableNameRequired: "Table name is required",
  emailRequired: "E-mail is required",
  emailValidMessage: "E-mail must be valid",
  passwordRequired: "Password is required",
  passwordValidMessage: "Password must be between 6 and 20",
  confirmValidMessage: "Your password and confirmation password do not match.",
  recordTypeRequired: "Record type is required",
  lengthValidMessage: "{fieldName} must be {type} than {size} characters",
  amountValidMessage: "{fieldName} must be {type} than {size}",
  occasionalCustomerNoInteger: "Occasional customer no should be a number",
  imageSize: "Image size should be less than 2 MB!",
  formAlert: "Please check the value of fields",
  itemCodeRequired: "Item code is required",
  itemDescriptionRequired: "Item description is required",
  integerValue: "The value should be a number",
  supplierRequired: "Supplier name is required",
  quantityInteger: "Quantity should be a number",
  titleRequired: "Title is required",
  productItemAlert: "A document must have at least one item",
  invoiceItemAlert: "A card must have at least one invoice",
  remarksItemAlert: "A card must have at least one remarks",
  quotationRequired: "Quotation is required",
  vatRegNoInteger: "VAT Reg. No. should be a number",
  orderRequired: "Order is required",
  deliveryCompanyRequired: "Delivery Company is required",
  ordernoInteger: "Order No. should be a number",
  invoicenoInteger: "Invoice No. should be a number",
  invoiceCompanyRequired: "Invoice Company is required",
  invoiceYearRequired: "Invoice year is required",
  receiptYearRequired: "Receipt year is required",
  paymentDateRequired: "Payment date is required",
  paymentTypeRequired: "Payment type is required",
  chequeNumberRequired: "Cheque number is required",
  bankNoRequired: "Bank no is required",
  branchRequired: "Branch no is required",
  accountNoRequired: "Account no is required",
  amountRequired: "Amount is required",
  paymentItemAlert: "A card must have at least one payment line",
  bankNameRequired: "Bank name is required",
  customerOrderNoRequired: "Customer order no. required",
  imageDimenstionRequired: "Required maximum {dimenstion} image!",
  bookkipingNoRequired: "Bookkeeping No. is required",
  incomeRequired: "Income is required",
  vatExemptedIncomeRequired: "VAT Exempted Income is required",
  incomeVatRequired: "VAT is required",
  purchasesRequired: "Overseas Income is required",
  customerHoldingTaxRequired: "Customer With Holding Tax is required",
  balanceSheetSectionRequired: "Balance Sheet Section is required",
  grossProfitRequired: "Gross Profit is required",
  expensesRequired: "Expenses is required",
  equipmentPurchaseVatRequired: "Equipment Purchase Vat is required",
  purchasesVatRequired: "Purchases Vat is required",
  supplierHoldingTaxRequired: "Supplier With Holding Tax is required",
  checksRequired: "Checks is required",
  cashRequired: "Cash is required",
  vatDebitCreditRequired: "VAT debit/credit is required",
  balanceCancelationRequired: "Balance Cancelation is required",
  employeePrefixRequired: "Prefix for employee is required",
  customerPrefixRequired: "Prefix for customer is required",
  supplierPrefixRequired: "Prefix for Supplier is required",
  securityCodeRequired: "Security code is required",
  dateValid: "Please enter a valid date in the format dd-mm-yyyy",
  dateValid2: "Please enter a valid date in the format yyyy-mm-dd",
  documentAttachedAlert: "A card must have at least one document",
  fileRequired: "Attached document is required",
  detialsRequired: "Details are required",
  documentTypeRequired: "Document type is required",
  discountRequired: "Discount is required",
  priceRequired: "Price is required",
  itemGroupRequired: "Item Group is required",
  priceListGroupRequired: "Price List Group is required",
  fromNoRequired: "From No. is required",
  documentNumberRequired: "Document number is required",
  seriesRequired: "Series is required",
  addressRequired: "Address is required",
  startTimeRequired: "Start time is required",
  endTimeRequired: "End time is required",
  eventDaysInteger: "Calendar event days should be a number",
  eventDaysValidMessage: "Calendar event days must be less than 3 characters",
  priorityRequired: "Priority is required",
  eventTypeRequired: "Event type is required",
  grandTotalRequestedInteger: "Grand total requested should be a number",
  lessGrandTotalRequested: "Grand total requested should be a less than grand total",
  lessPaidAmount: "The amount paid should be less than or equal to the grand total",
  suppliedQtyRequired: "Supplied quantity is required",
  untilDateRequired: "Until date is required",
  fromDateRequired: "From date is required",
  yearRequired: "Year is required",
  priceHigh: "Please check that the invoice total does not more than the total amount of the credit invoice",
  dateRequired: "Date is required",
  documentDetailsRequired: "Document detail is required",
  invalidFileFormat: "Please enter .doc file",
  updateWareHouse: "You must enter a value in the field from the list of warehouses.",
  fontSizeRequired: "Font size required",
  fontStyleRequired: "Font style required",
  fontWeightRequired: "Font weight required",
  templateSelectionValid: "Template is required",
  templateNameRequired: "Template name is required",
  textDecorationRequired: "Text decoration is required",
  suppliedQty: "Supplied quantity is not greater than quantity",
  quotationNoInteger: "Quotation no. should be a number",
  quotationNoValidMessage: "Quotation No. must be less than 8 characters",
  receivedByRequired: "Received by is required",
  deliveryNoteNoRequired: "Delivery Note No. is required",
  deliveryNoteNoInteger: "Delivery Note No. should be a number",
  fixedDiscountInteger: "Fixed discount should be a number",
  fixedDiscountValidMessage: "Fixed discount must be less than 2 characters",
  warehouseRequired: "Warehouse is required",
  invoiceNoRequired: "Invoice No. is required",
  creditInvoiceNoRequired: "Credit Invoice No. is required",
  creditInvoicenoInteger: "Credit Invoice No. should be a number",
  discountValueExceedsLimit: "Discount value cannot exceed 100.",
  negativeValueMessage: "Don't enter negative values",
  refreshToken: "User don't have refresh token so please click google button and sign in with your email."
}